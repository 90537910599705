<template>
  <el-dropdown-item v-bind="$attrs">
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-dropdown-item>
</template>

<script lang="ts" setup></script>
<style>
/* Todo: Find a sustainable way to make changes to the theme */
.el-dropdown-menu__item {
  @apply font-medium;
  --el-dropdown-menuItem-hover-fill: var(--gray300);
  --el-dropdown-menuItem-hover-color: var(--secondary);
  --el-text-color-regular: var(--secondary);
}
</style>
