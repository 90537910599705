<template>
  <el-dropdown
    class="dropdown"
    v-bind="$attrs"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup></script>
<style>
.el-dropdown__popper.el-popper {
  box-shadow: 0px 4px 16px rgba(31, 41, 55, 0.1);
  @apply border-0;
}
</style>
